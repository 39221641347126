export default {
  user: {
    uid: '',
    hash: '',
    displayName: '',
    // about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    photoURL: '',
    status: '',
    actualRole: 'admin',
    roles: [],
    // createdAt: user.created_at,
    // additionalInfo: user.additional_data,
    type: '',
  },
}
