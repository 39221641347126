import auth from "../../http/requests/auth/index";
import router from "../../router";

export default {
  // Auth
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .login(payload.userDetails.name, payload.userDetails.password)
        .then(async (response) => {
          if (response.data.ok) {
            const hash = `Basic ${btoa(
              `${payload.userDetails.name}:${payload.userDetails.password}`
            )}`;
            localStorage.setItem("userHash", hash);
            const user = (await auth.getUser(response.data.name, hash)).data;
            // console.log('user', user)
            auth.getRoles(response.data.roles[0], hash).then(() => {
              // console.log(_)
            });
            const userData = {
              id: response.data.name,
              hash,
              displayName: user.complete_name,
              // about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
              photoURL: user.avatar_url,
              status: user.complete_name,
              actualRole: "admin",
              // createdAt: user.created_at,
              // additionalInfo: user.additional_data,
              type: "USER",
            };
            commit("UPDATE_USER", userData);
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || "/");
            resolve(response);
          } else {
            reject(new Error("Usuario o contraseña invalido!!"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  recoveryPassword(_, payload) {
    return new Promise((resolve, reject) => {
      auth
        .recoveryPassword(payload.ticket, payload.newPassword)
        .then(async (response) => {
          // console.log(response)
          if (response.status === 401) reject(response.data.message);
          if (response.status === 200 || response.status === 204)
            resolve(response);
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  },
};
