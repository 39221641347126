import querystring from 'querystring'
import axios from '../../axios/index'

export default {

  login(name, password) {
    return axios.post('_session',
      querystring.stringify({
        name,
        password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
      })
  },
  recoveryPassword(ticket, newPassword) {
    return axios.post('auth/change-password/change',
      {
        ticket,
        new_password: newPassword,
      },
      {
        headers: {
          Accept: 'application/json',
        },
        validateStatus: false,
      })
  },
  getUser(name, hash) {
    return axios.get(`_users/org.couchdb.user:${name}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: hash,
          //  'withCredentials': 'true'
        },
      })
  },
  getRoles(id, hash) {
    return axios.get(`general/${id}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: hash,
          // 'withCredentials': 'true'
        },
      })
  },
}
